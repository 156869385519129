@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Set heroicon's stroke width */
.heroicon-sw-2 path {
  stroke-width: 2 !important;
}
.heroicon-sw-1\.75 path {
  stroke-width: 1.75 !important;
}
.heroicon-sw-1\.5 path {
  stroke-width: 1.5 !important;
}
.heroicon-sw-1\.2 path {
  stroke-width: 1.2 !important;
}

.group:hover .hv-absolute {
  display: block;
}

.hv-absolute {
  display: none;
  min-width: 150px;
  z-index: 1000;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: translateY(-10px);
  opacity: 0;
}

.group:hover .hv-absolute {
  transform: translateY(0);
  opacity: 1;
}

.fade-enter,
.fade-exit {
    opacity: 0;
    transition: opacity 2s;
}

.fade-enter-active,
.fade-exit-done {
    opacity: 1;
    transition: opacity 2s;
}